import { useEffect, useState } from "react";

const mobileListener = window.matchMedia("(max-width: 767px)");
const tabletListener = window.matchMedia(
  "(min-width: 768px) and (max-width: 1199px)",
);
const desktopListener = window.matchMedia("(min-width: 1200px)");

export default () => {
  const [isMobile, setMobile] = useState(mobileListener.matches);
  const [isTablet, setTablet] = useState(tabletListener.matches);
  const [isDesktop, setDesktop] = useState(desktopListener.matches);

  useEffect(() => {
    const handleMobileListener = ({ matches }) => setMobile(matches);
    const handleTabletListener = ({ matches }) => setTablet(matches);
    const handleDesktopListener = ({ matches }) => setDesktop(matches);

    mobileListener.addListener(handleMobileListener);
    tabletListener.addListener(handleTabletListener);
    desktopListener.addListener(handleDesktopListener);

    return () => {
      mobileListener.removeListener(handleMobileListener);
      tabletListener.removeListener(handleTabletListener);
      desktopListener.removeListener(handleDesktopListener);
    };
  }, []);

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};
