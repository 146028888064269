import * as Sentry from "@sentry/browser";

export default () => {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: process.env.REACT_APP_GIT_REV,
      environment: process.env.REACT_APP_DEPLOY_ENV,
      integrations: [
        Sentry.extraErrorDataIntegration({ depth: 10 }),
        Sentry.captureConsoleIntegration({ levels: ["warn", "error"] }),
      ],
      beforeSend(event) {
        // Skip sending development events
        if (process.env.NODE_ENV === "development") {
          return null;
        }

        return event;
      },
    });
  }
};

export const sendError = (message, extra, exception) => {
  if (process.env.NODE_ENV === "development") {
    console.error(message, extra, exception);
  } else {
    Sentry.withScope((scope) => {
      if (extra) {
        scope.setExtras(extra);
      }

      if (exception) {
        scope.setExtra("message", message);
        Sentry.captureException(exception);
      } else {
        Sentry.captureMessage(message);
      }
    });
  }
};
