import React, { useCallback } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { graphql } from "babel-plugin-relay/macro";
import { castArray, every } from "lodash/fp";
import { flow, find } from "lodash";

import FeatureToggleContext from "context/FeatureToggleContext";

const query = graphql`
  query FeatureTogglesProviderQuery {
    featureToggles {
      id
      name
      enabled
    }
  }
`;

export default (props) => {
  const { children } = props;
  const { featureToggles } = useLazyLoadQuery(query);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hasFeatureToggles = useCallback(
    flow([
      castArray,
      every((toggleToCheck) =>
        find(featureToggles, { name: toggleToCheck, enabled: true }),
      ),
    ]),
    [featureToggles],
  );

  return (
    <FeatureToggleContext.Provider value={hasFeatureToggles}>
      {children}
    </FeatureToggleContext.Provider>
  );
};
