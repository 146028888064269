import { createGlobalStyle } from "styled-components";
import { media } from "ui/media";
import fontelloEot from "font/fontello.eot?31516366";
import fontelloEotIeFix from "font/fontello.eot?31516366#iefix";
import fontelloWoff2 from "font/fontello.woff2?31516366";
import fontelloWoff from "font/fontello.woff?31516366";
import fontelloTtf from "font/fontello.ttf?31516366";
import fontelloSvg from "font/fontello.svg?31516366#fontello";
import theme from "theme";

export const GlobalStyle = createGlobalStyle`
/* This file is for global styles only */

:root {
  font-size: 16px;
  font-family: ${theme.defaultFont};

  ${media.phone} {
    font-size: 14px;
    position: fixed; /* prevents empty scroll region due to hidden sidebar */

    @media print {
      position: relative !important;
    }
  }
}

@font-face {
  font-family: fontello;
  src: url('${fontelloEot}');
  src:
    url('${fontelloEotIeFix}') format('embedded-opentype'),
    url('${fontelloWoff2}') format('woff2'),
    url('${fontelloWoff}') format('woff'),
    url('${fontelloTtf}') format('truetype'),
    url('${fontelloSvg}') format('svg');
  font-weight: normal;
  font-style: normal;
}

.fa {
  font-size: 14px;
}

*, *:before, *:after {
  box-sizing: border-box;
  font-family: inherit;

}

body {
  font-size: 1rem;
  line-height: 1.4;
  color: ${theme.defaultFontColor};
  background: ${theme.white};
}

html,
body,
main {
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100%;
  max-width: 100%;
  font-family: ${theme.defaultFont};
  overflow: hidden;
  @media print {
    height: initial !important;
    overflow: initial !important;
  }
}

ul {
  list-style: none;
}

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}


img,
embed,
object,
audio,
video {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.content:not(:last-child) {
  margin-bottom: 1.5rem;
}


.content ul {
  list-style: disc outside;
  margin-left: 2em;
  margin-top: 1em;
}

.content ul ul {
  list-style-type: circle;
  margin-top: 0.5em;
}

.content ul ul ul {
  list-style-type: square;
}

@page {
  margin: 4.5mm 10mm;
  size: 1210px 935px landscape;
}

h1 {
  margin: 0.67em 0;
}

h4, h5, p {
    line-height: 145%;
}

h1, h2, h3, h4, h5, p {
    margin-bottom: 0.75rem;
}

h1, h2, h3, h4 {
    color: ${theme.headerText};
}

.has-text-centered {
    text-align: center !important;
}

tr th {
  text-align: left;
}

input[type="radio"] {
  appearance: radio;
  height: auto;
}

select {
  /* Not sure why border from bulma is not showing up */
  border-bottom: 1px solid ${theme.bannerGrey};
  outline: none;
}

textarea {
  font-size: 1.45rem;
  color: ${theme.textAreaText};
  width: 100%;
  padding: 0.65rem 0.85rem;
  border: 1px solid ${theme.placeholderGrey};
  border-radius: 0.325rem;
  margin-bottom: 1rem;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

textarea:focus {
  -webkit-box-shadow: 0;
  box-shadow: 0;
  outline: none;
  border-color: ${theme.validGreen};
}

textarea::-webkit-input-placeholder {
  color: ${theme.placeholderGrey};
}

textarea:-ms-input-placeholder {
  color: ${theme.placeholderGrey};
}

textarea::placeholder {
  color: ${theme.placeholderGrey};
}


/* Green checkboxes */
input[type="checkbox"] {
  appearance: none;
  background-color: ${theme.alternateSectionGrey};
  border: 1px solid ${theme.checkBorder};
  box-shadow: 0 1px 2px rgba(${theme.blackRGB},0.05), inset 0px -15px 10px -12px rgba(${theme.blackRGB},0.05);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative;

  &:active, &:checked:active {
    box-shadow: 0 1px 2px rgba(${theme.blackRGB},0.05), inset 0px 1px 3px rgba(${theme.blackRGB},0.1);
  }

  &:checked {
    background-color: ${(props) => props.theme.primary};
    border: 1px solid ${theme.checkBorderChecked};
    box-shadow: 0 1px 2px rgba(${theme.blackRGB},0.05), inset 0px -15px 10px -12px rgba(${theme.blackRGB},0.05), inset 15px 10px -12px rgba(${theme.whiteRGB},0.1);
    color: ${theme.checkTextChecked};

    &:after {
      content: '\2714';
      font-size: 1rem;
      position: absolute;
      top: -1px;
      left: 5px;
      color: ${theme.black};
    }
  }
}
/* /Green checkboxes */

button,
input,
select,
textarea {
  background-color: ${theme.transparent}
  border-style: none;
  color: inherit;
  font-size: 1em;
  margin: 0;
}

input[type="text"], input[type="number"], input[type="email"], input[type="password"], .select select {
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: 1px solid ${theme.placeholderGrey};
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 1.45rem;
  width: 100%;
  min-height: 26px;
  height: auto;
  color: ${theme.textAreaText};
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  padding-left: 4px;
  padding-right: 4px;}
/* /Bulma overrides */
`;
