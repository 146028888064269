import React, { useState } from "react";
import styled from "styled-components";
import { StackedInput } from "./StackedInput";

const Toggle = (hidden, toggleShow) => {
  return (
    <ToggleContainer>
      <pw-i-lined-show
        width={24}
        onClick={toggleShow}
        style={{ display: hidden ? "none" : "inherit", cursor: "pointer" }}
      />
      <pw-i-lined-hide
        width={24}
        onClick={toggleShow}
        style={{ display: hidden ? "inherit" : "none", cursor: "pointer" }}
      />
    </ToggleContainer>
  );
};
const PasswordInputBase = ({ className, ...rest }) => {
  const [hidden, setHidden] = useState(true);
  const toggleShow = () => setHidden(!hidden);
  return (
    <Container className={className}>
      <StackedInput
        type={hidden ? "password" : "text"}
        autoComplete="off"
        SuffixComponent={Toggle(hidden, toggleShow)}
        {...rest}
      />
    </Container>
  );
};

export const ToggleContainer = styled.div`
  &:first-child {
    position: absolute;
    bottom: 56px;
    right: 13px;
    display: flex;
  }
  &:last-child {
    position: absolute;
    bottom: 105px;
    right: 13px;
    display: flex;
    justify-content: flex-start;
    height: 24px;
  }
`;

const Container = styled.div``;

export const PasswordInput = styled(PasswordInputBase)`
  > .check {
    margin-top: 10px;
    display: block;
  }
`;
