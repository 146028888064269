export const iconMapper = {
  envelope: "\\e800",
  error: "\\e801",
  creditcard: "\\e802",
  handset: "\\e803",
  bulletpoint: "\\e804",
  handandcoin: "\\e805",
  chatbubble: "\\e806",
  moneybag: "\\e807",
  financedetails: "\\e808",
  coinuparrow: "\\e809",
  house: "\\e80a",
  leftarrowcircle: "\\e80b",
  mortarboard: "\\e80c",
  smile: "\\e80d",
  "big-chevron-right": "\\e825",
  check: "\\e816",
  umbrella: "\\e80f",
  arrow: "\\e810",
  checkincircle: "\\e811",
  cross: "\\e812",
  threelinemenu: "\\e813",
  pencil: "\\e814",
  "angle-left": "\\f104",
  "angle-right": "\\f105",
  "angle-up": "\\f106",
  "angle-down": "\\f107",
  "check-square": "\\f14a",
  square: "\\f096",
  "error-inverse": "\\e815",
  info: "\\f085",
  ellipsis: "\\f141",
  eye: "\\e816",
  "question-circle-o": "\\f29c",
  video: "\\E81E",
  message: "\\E821",
  blog: "\\E820",
  tshirt: "\\E81D",
  friends: "\\E81C",
  "pie-chart": "\\E81B",
  like: "\\E81A",
  monitor: "\\E819",
  lock: "\\E818",
  attentionone: "\\E81f",
  uploadicongrey: "\\E823",
  dashBack: "\\E824",
};
