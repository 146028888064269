import { get, fromPairs } from "lodash";

import { refreshFeatureToggles } from "mutations/RefreshFeatureTogglesMutation";
import { sendError } from "helpers/errorTracking";

/* global analytics */
let currentFeatureToggles = {};

export const refreshCurrentFeatureToggles = async () => {
  try {
    const payload = await refreshFeatureToggles();
    const featureToggles = get(
      payload,
      "refreshFeatureToggles.featureToggles",
      {},
    );
    const featureToggleStrings = fromPairs(
      featureToggles.map(({ name, enabled }) => [`toggle_${name}`, enabled]),
    );
    currentFeatureToggles = { ...featureToggleStrings };
  } catch (e) {
    sendError(
      `In Metrics.${refreshCurrentFeatureToggles.caller.name}()`,
      null,
      e,
    );
  }
};

const segmentInitialize = (segment_api_key) => {
  var i = "analytics",
    analytics = (window[i] = window[i] || []);
  if (!analytics.initialize) {
    if (analytics.invoked) {
      window.console &&
        console.error &&
        console.error("Segment snippet included twice.");
    } else {
      analytics.invoked = !0;
      analytics.methods = [
        "trackSubmit",
        "trackClick",
        "trackLink",
        "trackForm",
        "pageview",
        "identify",
        "reset",
        "group",
        "track",
        "ready",
        "alias",
        "debug",
        "page",
        "screen",
        "once",
        "off",
        "on",
        "addSourceMiddleware",
        "addIntegrationMiddleware",
        "setAnonymousId",
        "addDestinationMiddleware",
        "register",
      ];
      analytics.factory = function (e) {
        return function () {
          if (window[i].initialized) {
            return window[i][e].apply(window[i], arguments);
          }
          var n = Array.prototype.slice.call(arguments);
          if (
            ["track", "screen", "alias", "group", "page", "identify"].indexOf(
              e,
            ) > -1
          ) {
            var c = document.querySelector("link[rel='canonical']");
            n.push({
              __t: "bpc",
              c: (c && c.getAttribute("href")) || void 0,
              p: location.pathname, //eslint-disable-line no-restricted-globals
              u: location.href, //eslint-disable-line no-restricted-globals
              s: location.search, //eslint-disable-line no-restricted-globals
              t: document.title,
              r: document.referrer,
            });
          }
          n.unshift(e);
          analytics.push(n);
          return analytics;
        };
      };
      for (var n = 0; n < analytics.methods.length; n++) {
        var key = analytics.methods[n];
        analytics[key] = analytics.factory(key);
      }
      analytics.load = function (key, n) {
        var t = document.createElement("script");
        t.type = "text/javascript";
        t.async = !0;
        t.setAttribute("data-global-segment-analytics-key", i);
        t.src =
          "https://cdn.segment.com/analytics.js/v1/" +
          key +
          "/analytics.min.js";
        var r = document.getElementsByTagName("script")[0];
        r.parentNode.insertBefore(t, r);
        analytics._loadOptions = n;
      };
      analytics._writeKey = segment_api_key;
      analytics.SNIPPET_VERSION = "5.2.0";
    }
  }
  analytics.load(segment_api_key);
  analytics.page();
};

const MetricsStub = {
  track: (event, payload) =>
    console.log(`Metrics.track(): ${event}`, {
      ...payload,
      featureToggles: { ...currentFeatureToggles },
    }),
  page: (category, name, payload) =>
    console.log(`Metrics.page(): ${category} ${name}`, {
      ...payload,
      featureToggles: { ...currentFeatureToggles },
    }),
  identify: (id, traits, callback) => {
    console.log(`Metrics.identify(): ${id}`, traits);
    refreshCurrentFeatureToggles();
    if (callback) {
      setTimeout(callback, 300);
    }
  },
  alias: (data) => {
    console.log("Metrics.alias():", data);
    refreshCurrentFeatureToggles();
  },
  setup: () => console.log("Metrics.setup()"),
};

const Metrics = {
  track: (event, payload) => {
    analytics.track(event, {
      ...payload,
      featureToggles: { ...currentFeatureToggles },
    });
  },
  page: (category, name, payload) => {
    analytics.page(category, name, {
      ...payload,
      featureToggles: { ...currentFeatureToggles },
    });
  },
  identify: (id, traits, callback) => {
    const mergedTraits = { ...traits, region: process.env.REACT_APP_REGION };
    analytics.identify(id, { traits: mergedTraits }, {}, callback);
    refreshCurrentFeatureToggles();
  },
  alias: ({ id }) => {
    analytics.alias(id);
    analytics.identify(id, {
      traits: { region: process.env.REACT_APP_REGION },
    });
    refreshCurrentFeatureToggles();
  },
  setup: () => segmentInitialize(process.env.REACT_APP_SEGMENT_API_KEY),
};

const getMetrics = () =>
  process.env.REACT_APP_METRICS_ENABLED ? Metrics : MetricsStub;

export default getMetrics();
