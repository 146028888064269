function retry(fn, retries = 5, delay = 1000) {
  return new Promise((resolve, reject) => {
    const attempt = (retriesLeft) => {
      fn()
        .then(resolve)
        .catch((error) => {
          if (retriesLeft === 0) {
            reject(error);
          } else {
            setTimeout(attempt, delay, retriesLeft - 1);
          }
        });
    };
    attempt(retries);
  });
}

export default retry;
