import React from "react";
import styled from "styled-components";
import { useLingui } from "@lingui/react";
import { useLocation } from "react-router-dom";
import { useFeatureToggles } from "hooks";
import LocaleDropdown from "components/LocaleDropdown";

const LocaleSelectorBase = (props) => {
  const { i18n } = useLingui();
  const hasFeatureToggles = useFeatureToggles();
  const { pathname } = useLocation();

  const selectLocale = ({ target: { value } }) => {
    const newUrl = pathname.replace(/\/[a-z]{2}-[A-Z]{2}/, `/${value}`);
    window.location = newUrl;
  };

  return hasFeatureToggles("locale_selector") ? (
    <LocaleDropdown
      {...props}
      name="locale"
      onChange={selectLocale}
      defaultValue={i18n.locale}
    />
  ) : null;
};

const LocaleSelector = styled(LocaleSelectorBase)`
  position: fixed;
  top: 0;
  right: 1em;
  z-index: 9001;
`;

export default LocaleSelector;

export const WithLocaleSelector = (Component) => (props) => (
  <>
    <LocaleSelector match={props.match} />
    <Component {...props} />
  </>
);
