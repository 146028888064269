export const media = {
  // Planswell 1.0
  smallPhone: "@media ( max-width: 360px )",
  phone: "@media ( max-width: 767px )",
  tablet: "@media ( min-width: 768px )",
  desktop: "@media ( min-width: 1200px )",
  internetExplorer:
    "@media (-ms-high-contrast: none), (-ms-high-contrast: active)",
  notInternetExplorer:
    "@media not (-ms-high-contrast: none), (-ms-high-contrast: active)",
  // for the edit user modal button wrapper
  m600To767: "@media (min-width: 600px) and ( max-width: 767px )",
  //  leos stuff TO BE USED FOLLOWING OCTOBER 1, 2020
  mobile: "@media (min-width: 0px) and ( max-width: 767px )",
  tabletPortrait: "@media (min-width: 768px) and ( max-width: 1023px )",
  tabletLandscape: "@media (min-width: 1024px) and ( max-width: 1439px )",
  desktopSmall: "@media (min-width: 1440px) and ( max-width: 2559px )",
  desktopGutterLimit: "@media ( min-width: 1327px )",
  desktopKindaHD: "@media ( min-width: 1921px )",
  tablet1024m: "@media (min-width: 1024px )",
  desktop1440m: "@media (min-width: 1440px)",
  desktopHD: "@media ( min-width: 2560px )",
  // dont use small mobile and mobile in the same class, small mobile is for special usecase
  smallMobile: "@media (min-width: 0px) and (max-width: 425px)",
  // Meagan's media queries which match the grid Michael created. to be used May 24, 2021 and on
  phone600: "@media (max-width: 600px)",
  tablet768: "@media (max-width: 768px)",
  tablet1024: "@media (max-width: 1024px)",
  desktop1440: "@media (max-width: 1440px)",
  desktop2560: "@media (max-width: 2560px)",
  desktopHDD: "@media (min-width: 2561px)",
};
