import styled from "styled-components";

import { getColor } from "../defaults";
import { iconMapper } from "../icons_available";

export const Icon = styled.span`
  color: ${getColor};
  font-size: ${(props) => props.size || "inherit"};
  font-family: fontello, sans-serif;
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-align: center;
  font-variant: normal;
  text-transform: none;

  &::before {
    content: "${(props) => iconMapper[props.name]}";
  }
`;
