/**
 * @generated SignedSource<<49c4d52f14dbd0390ab33cc2cf4d0783>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {
          "noInputValue": ""
        }
      }
    ],
    "concreteType": "RefreshFeatureTogglesPayload",
    "kind": "LinkedField",
    "name": "refreshFeatureToggles",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FeatureToggle",
        "kind": "LinkedField",
        "name": "featureToggles",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabled",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "refreshFeatureToggles(input:{\"noInputValue\":\"\"})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RefreshFeatureTogglesMutation",
    "selections": (v0/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RefreshFeatureTogglesMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "c6f0ba3e52adbe08729ffe2fcc877ecc",
    "id": null,
    "metadata": {},
    "name": "RefreshFeatureTogglesMutation",
    "operationKind": "mutation",
    "text": "mutation RefreshFeatureTogglesMutation {\n  refreshFeatureToggles(input: {noInputValue: \"\"}) {\n    featureToggles {\n      id\n      name\n      enabled\n    }\n  }\n}\n"
  }
};
})();

node.hash = "eb5a703793cbed4dff776c47a9c98376";

module.exports = node;
