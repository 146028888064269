/**
 * @generated SignedSource<<1ba97c3367db25454a084d3a5478e4b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "resetToken"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "resetToken",
        "variableName": "resetToken"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "kind": "ScalarField",
    "name": "checkResetToken",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckResetTokenQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckResetTokenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fb40610a5ec3c5637249305cc1804da6",
    "id": null,
    "metadata": {},
    "name": "CheckResetTokenQuery",
    "operationKind": "query",
    "text": "query CheckResetTokenQuery(\n  $resetToken: String!\n  $type: TokenType\n) {\n  checkResetToken(resetToken: $resetToken, type: $type)\n}\n"
  }
};
})();

node.hash = "f41f1976ae7361d1be1f2cfd014f31c6";

module.exports = node;
