import React from "react";
import styled from "styled-components";
import Login from "containers/Login";
import Logo from "containers/Logo";
import { media } from "ui/media";
import LegalLinks from "./../../components/LegalLinks";
import { useMedia } from "hooks";
import theme from "theme";

const LoginPage = (props) => {
  const { isDesktop } = useMedia();

  return (
    <OuterContainer>
      <Wrapper>
        <BodyLayout>
          <LoginWrapper>
            <LogoContainer>
              {isDesktop ? <Logo size="large" /> : <Logo size="medium" />}
            </LogoContainer>
            <LoginContainer>
              <Login action={props.action} />
            </LoginContainer>
          </LoginWrapper>
          <LegalLinkContainer>
            <LegalLinksLogin />
          </LegalLinkContainer>
        </BodyLayout>
        <ImageContainer>
          <picture>
            <source srcSet={theme.vista} type="image/webp" />
            <img src={theme.vistaPng} alt="vista" width="100%" height="100%" />
          </picture>
        </ImageContainer>
      </Wrapper>
    </OuterContainer>
  );
};

export default LoginPage;

export const LogoContainer = styled.div`
  position: fixed;
  left: 112px;
  top: 39px;
  display: flex;
  justify-content: center;
  z-index: 0;
  @media screen and (max-height: 656px) {
    display: none;
  }
  ${media.mobile} {
    top: 24px;
    left: 12px;
  }
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 1;
  input {
    padding-left: 12px;
  }
`;

export const OuterContainer = styled.div`
  display: grid;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;

export const BodyLayout = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  width: 100vw;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  position: absolute;
`;

export const LegalLinkContainer = styled.div`
  position: absolute;
  bottom: 0;
  background: ${({ theme }) => theme.legalGreen};
  width: 100vw;
  z-index: 2;
  a {
    color: ${({ theme }) => theme.white};
    display: flex;
    flex-direction: row;
    position: relative;
    top: 5px;
  }

  ${media.mobile} {
    position: fixed;
  }
`;

export const FooterContainer = styled.div`
  display: block;
  width: 100vw;
`;

export const SignupLinkContainer = styled.div`
  position: relative;
  z-index: 2;
`;

export const ImageContainer = styled.div`
  position: fixed;
  bottom: 40px;
  left: 0;
  ${media.mobile} {
    display: none;
  }
`;

export const LegalLinksLogin = styled(LegalLinks)`
  ${media.phone} {
    margin-bottom: 10px !important;
  }
`;
