import React from "react";
import styled from "styled-components";
import theme from "theme";

import { Input } from "./Input";

const textColor = (props) => (props.errorMsg ? theme.errorOrange : "inherit");
const inputColor = (props) => (props.errorMsg ? theme.protect : props.color);

const StackedInputBase = React.forwardRef(
  ({ className, label, errorMsg, component, name, ...rest }, ref) => {
    const InputComponent = component || Input;

    return (
      <div className={className}>
        <StackedInputLabel htmlFor={name}>{label}</StackedInputLabel>
        <InputComponent
          ref={ref}
          color={inputColor(rest)}
          name={name}
          {...rest}
        />
        <div className="errorMsg">{errorMsg}</div>
      </div>
    );
  },
);

export const StackedInput = styled(StackedInputBase)`
  > .errorMsg {
    display: ${(props) => (props.errorMsg ? "block" : "none")};
    font-style: ${(props) => (props.errorMsg ? "italic" : "inherit")};
    font-family: ${({ theme }) => theme.defaultFont};
    font-weight: normal;
    color: ${textColor};
  }
`;

export const StackedInputLabel = styled.label`
  display: block;
  margin-bottom: 0.2rem;
  color: ${textColor};
`;
