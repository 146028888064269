import React, { useState } from "react";
import styled from "styled-components";

function ToolTip({ info, toolBottom, infoLink }) {
  const [isVisible, setIsVisible] = useState(false);

  const handleHoverState = () => {
    setIsVisible(!isVisible);
  };

  return (
    <React.Fragment>
      <Wrapper>
        <pw-i-filled-info
          width={16}
          strokecolor="none"
          fillcolor="grey"
          onClick={handleHoverState}
        />
      </Wrapper>
      {isVisible && (
        <Inner bottom={toolBottom}>
          <pw-tooltip bottom={toolBottom}>
            <div slot="main" class="primary">
              {info}{" "}
              {infoLink &&
              ["USA", "FATFIRE-USA"].includes(process.env.REACT_APP_REGION) ? (
                <a
                  style={{ color: "lightgreen" }}
                  href={infoLink.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  {infoLink.title}
                </a>
              ) : null}
            </div>
          </pw-tooltip>
        </Inner>
      )}
    </React.Fragment>
  );
}

const Wrapper = styled.div`
  position: relative;
  justify-content: center;
  margin: 0 2.5px;
  display: inline;

  pw-i-filled-info {
    display: inline-flex;
    vertical-align: middle;
    padding-bottom: 2.5px;
    cursor: pointer;
  }
`;

const Inner = styled.div`
  position: relative;
  z-index: 3300;
  max-width: 100%;

  pw-tooltip {
    background-color: ${({ theme }) => theme.tooltip} !important;
    &::after {
      display: none;
    }
  }
`;

export default ToolTip;
