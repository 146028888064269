import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import Metrics from "helpers/metrics";
import { PasswordInput } from "./PasswordInput";
import PuiButton from "ui/buttons/PuiButton";

const PasswordBase = ({
  children,
  className,
  email,
  password,
  onChange,
  onSubmit,
  loginError,
}) => {
  const { i18n } = useLingui();

  useEffect(() => {
    Metrics.track("login: enter-password-page");
  }, []);

  const onInvalid = ({ target }) => {
    const errorMessage = t`Please enter a valid password`;
    target.setCustomValidity(errorMessage);
  };

  const resetValidity = ({ target }) => {
    target.setCustomValidity("");
    target.checkValidity();
  };

  return (
    <pw-row style={{ justifyContent: "center" }} gutter="md">
      <pw-col
        xxxl="4"
        xl="5"
        lg="6"
        md="8"
        sm="10"
        gutter="sm"
        style={{ maxWidth: "384px" }}
      >
        <PasswordForm
          className={className}
          onSubmit={onSubmit}
          onChange={resetValidity}
        >
          <pw-row gutter="md">
            <pw-col xxxl="12" gutter="md">
              <pw-row style={{ justifyContent: "space-between" }}>
                <pw-col>
                  <LoginTextTitle>Login</LoginTextTitle>
                </pw-col>
                <pw-col>
                  <ForgotPassword
                    to={{
                      pathname: `/${i18n.locale}/reset-password`,
                      state: { email },
                    }}
                  >
                    <Trans>Forgot password?</Trans>
                  </ForgotPassword>
                </pw-col>
              </pw-row>
            </pw-col>

            <pw-col xxxl="12" gutter="md">
              <Error>{loginError}</Error>
              <AdaptedInput
                label={t`Password`}
                name="password"
                onChange={onChange}
                onInvalid={onInvalid}
                value={password}
                required
                autoFocus
              />
            </pw-col>
            <pw-col xxxl="12" gutter="md">
              <pw-row gutter="lg">
                <pw-col xxxl="5">
                  <CTA className="calls-to-action">{children}</CTA>
                </pw-col>
                <pw-col xxxl="7">
                  <PuiButton type="submit" tabIndex="0" isSecondary>
                    <Trans>Login</Trans>
                  </PuiButton>
                </pw-col>
              </pw-row>
            </pw-col>
          </pw-row>
        </PasswordForm>
      </pw-col>
    </pw-row>
  );
};

export const Password = styled(PasswordBase)`
  p {
    color: ${({ theme }) => theme.textGrey};
    font-size: 1rem;
    margin-top: 5px;
  }

  a {
    text-decoration: underline;
  }

  > .calls-to-action > * {
    display: inline-block;

    &:first-child {
      margin-left: 0;
    }
  }
`;

const Error = styled.div`
  color: ${({ theme }) => theme.danger};
  font-size: 1rem;
  margin-top: 0.75rem;
`;

const ForgotPasswordLink = styled(Link)`
  color: ${({ theme }) => theme.deemphasizedGrey};
  display: block;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  text-decoration: none !important;
`;

const AdaptedInput = styled(PasswordInput)`
  text-align: left;
  margin-bottom: 1rem;

  label {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${({ theme }) => theme.midGrey};
  }

  input {
    width: 100%;
    height: 48px;
    margin: 8px 0 0;
    border-radius: 8px;
    border: solid 1px ${({ theme }) => theme.validGrey} !important;
    background-color: ${({ theme }) => theme.white};
    padding-top: 5px;
    padding-left: 12px !important;
  }

  input[style*="background-image"] ~ [class*="ToggleContainer"] {
    right: 30px;
  }
`;

const ForgotPassword = styled(ForgotPasswordLink)`
  color: ${({ theme }) => theme.primary};
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  /* top: 57px;
  right: 5px; */
  text-decoration: underline !important;
`;

const PasswordForm = styled.form`
  position: relative;
  bottom: 163px;
  width: 100%;
  @media screen and (min-width: 600px) and (max-width: 768px) {
    bottom: 142px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    bottom: 146px;
  }
  @media screen and (min-width: 1441px) and (max-width: 2560px) {
    bottom: 140px;
  }
`;

const CTA = styled.div`
  position: relative;
  bottom: 0px;
  left: 4px;
  a {
    text-decoration: underline;
  }
`;

const LoginTextTitle = styled.div`
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.midGrey};
  position: relative;
  right: 0px;
  /* top: 48px;
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    top: 56px;
  } */
`;
