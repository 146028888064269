import { graphql } from "babel-plugin-relay/macro";
import { makeQueryAsync } from "helpers/queryAsync";

const CheckResetTokenQuery = graphql`
  query CheckResetTokenQuery($resetToken: String!, $type: TokenType) {
    checkResetToken(resetToken: $resetToken, type: $type)
  }
`;

export default CheckResetTokenQuery;
export const checkResetToken = makeQueryAsync(CheckResetTokenQuery);
