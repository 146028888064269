import React, { useState } from "react";
import styled from "styled-components";
import { t } from "@lingui/macro";

import { v4 as uuidv4 } from "uuid";
import { getColor } from "../defaults";
import ToolTip from "containers/PlanProUI/components/ToolTip";

import { Badge } from "ui/badges/Badge";

const textColor = (props) => (props.errorMsg ? "red" : "inherit");

const CheckboxBase = ({
  className,
  label,
  errorMsg,
  name,
  value,
  initValue,
  info,
  toolBottom,
  onChange,
  highlightInitValue,
  ...rest
}) => {
  const handleChange = (e) => {
    if (typeof onChange === "function") {
      return onChange({
        target: {
          name: e.target.name,
          value: e.target.checked ? "YES" : "NO",
          checked: e.target.checked,
          type: "checkbox",
        },
      });
    }
  };

  const [inputId] = useState(uuidv4());
  return (
    <div className={className}>
      {initValue &&
        !(initValue === "NO" && value === false) &&
        initValue !== value && (
          <BadgeContainer>
            <PreviousValueBadge grey highlight={highlightInitValue}>
              {t`was`} {initValue && initValue !== t`NO` ? t`YES` : t`NO`}
            </PreviousValueBadge>
          </BadgeContainer>
        )}
      <input
        {...rest}
        onChange={handleChange}
        checked={value === "YES" || value === true}
        type="checkbox"
        name={name}
        id={inputId}
      />
      <label htmlFor={inputId} aria-checked="false" aria-labelledby={name}>
        {label} {info ? <ToolTip info={info} toolBottom={toolBottom} /> : ""}
      </label>
      <div className="errorMsg">{errorMsg}</div>
    </div>
  );
};

export const Checkbox = styled(CheckboxBase)`
  > label {
    color: ${textColor};
    user-select: none;
  }

  > input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    z-index: -999;

    &:focus + label {
      outline: auto;
    }

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      &:focus + label {
        border: dotted 1px ${({ theme }) => theme.black};
      }
    }
  }

  > input[type="checkbox"] + label:before {
    font-family: fontello, sans-serif;
    color: ${({ theme }) => theme.deemphasizedGrey};
    font-size: 1em;
    content: "\\f096";
    font-weight: normal;
    margin-right: 0.625rem;
    background-color: ${({ theme }) => theme.white};
    background-size: cover;
  }

  > input[type="checkbox"][disabled] + label:before {
    background-color: ${({ theme }) => theme.lighterGrey};
  }

  > input[type="checkbox"]:checked + label:before {
    color: ${getColor};
    content: "\\f14a";
  }

  > .errorMsg {
    display: ${(props) => (props.errorMsg ? "block" : "none")};
    font-style: ${(props) => (props.errorMsg ? "italic" : "inherit")};
    color: ${textColor};
  }
`;

const BadgeContainer = styled.div`
  position: relative;
  height: 0;
`;

const PreviousValueBadge = styled(Badge)`
  position: absolute;
  top: 28px;
  left: 4px;
  padding: 0.1rem 0.3rem;
  font-size: 0.7rem;
  min-width: 58px;
  text-align: center;
`;
