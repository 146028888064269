import { commitMutation } from "react-relay";

import environment from "../helpers/RelayEnvironment";

// Returns a function called from a component that calls mutation with data
// and callbacks

// To create mutation execution function

// eg. import makeMutationExec from '...';
//     const LoginAndStuffMutation = graphql`blah...`
//     export default LoginAndStuffMutation;
//     export const execLoginAndStuff = makeMutationExec(LoginAndStuffMutation);

// To call mutation

// eg. import { execLoginAndStuff } from '...';
//     execLoginAndStuff({ email, password }, { onSuccess, onFailure });

// Note: It is imperative that what is being exported as default is the
// mutation itself, i.e., the tagged graphql string, NOT the exec function
// produced by makeMutationExec() as Relay's compiler expects the mutation to
// be the default export.

const makeMutationExec =
  (mutation) =>
  (
    data = {},
    callbacks = {
      onCompleted: () => undefined,
      onError: () => undefined,
    },
  ) => {
    const { onCompleted, onError } = callbacks;
    const variables = {
      input: { ...data },
    };
    return commitMutation(environment, {
      mutation,
      variables,
      onCompleted,
      onError,
    });
  };

export default makeMutationExec;
