import styled from "styled-components";
import { PasswordInput as PasswordInputBase } from "ui/inputs/PasswordInput";
import { Checkbox } from "ui/inputs/Checkbox";

export const PasswordInput = styled(PasswordInputBase)`
  text-align: left;
  margin-bottom: 1rem;

  label {
    font-size: 0.8rem;
    font-weight: bold;
  }

  input {
    font-family: ${({ theme }) => theme.defaultFont};
    color: ${({ theme }) => theme.textGrey};
  }

  ${Checkbox} {
    margin-top: 0.5rem;

    label {
      color: ${({ theme }) => theme.deemphasizedGrey};
      font-size: 1rem;
      font-weight: normal;
    }
  }
`;
