import { graphql } from "babel-plugin-relay/macro";
import { makeMutationAsync } from "helpers/mutationAsync";

const UserPasswordCreateMutation = graphql`
  mutation UserPasswordCreateMutation($input: UserPasswordCreateInput!) {
    userPasswordCreate(input: $input) {
      ok
    }
  }
`;

export default UserPasswordCreateMutation;
export const userPasswordCreate = makeMutationAsync(UserPasswordCreateMutation);
