import styled from "styled-components";
import { transparentize } from "polished";

import { getColor } from "../defaults";
import theme from "theme";

const backgroundColor = (props) => {
  const backTransparency = props.highlight ? 0 : 0.6;
  if (props.inverse) {
    return getColor(props);
  } else if (props.grey) {
    return transparentize(backTransparency, "lightgray");
  } else {
    return transparentize(0.9, getColor(props));
  }
};

const foregroundColor = (props) => {
  const foreTransparency = props.highlight ? 0 : 0.5;
  if (props.inverse) {
    return `${theme.white}`;
  } else if (props.grey) {
    return transparentize(foreTransparency, theme.black);
  } else {
    return getColor(props);
  }
};

const borderRadius = (props) => {
  if (props.grey) {
    return "border-radius: 4px;";
  } else {
    return null;
  }
};

const highlight = (props) => {
  if (props.highlight) {
    return `font-size: 0.8rem !important;
            margin-top: -4px;`;
  } else {
    return null;
  }
};

export const Notice = styled.div`
  color: ${foregroundColor};
  background-color: ${backgroundColor};
  ${borderRadius}
  ${highlight}
`;
