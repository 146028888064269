import React from "react";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";

const Loader = (props) => {
  const { i18n } = useLingui();
  const defaultMessages = [t`Carry the one...`, t`Crunching the numbers...`];
  const messages = props.message ? [i18n._(props.message)] : defaultMessages;
  // TODO: Support props.showCountdown?
  return (
    <div
      id="show-loader"
      messages={messages}
      quiet={props.quiet ? "true" : "false"}
    ></div>
  );
};

export default Loader;
