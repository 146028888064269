import { commitMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";

import environment from "helpers/RelayEnvironment";
import { makeMutationAsync } from "helpers/mutationAsync";

const mutation = graphql`
  mutation UserSignInMutation($input: UserSignInInput!) {
    userSignIn(input: $input) {
      accessToken
      doSms
      user {
        id
        email
        firstName
        locale
        role
        uuid
        householdId
        referredBy
        featureToggles {
          id
          name
          enabled
        }
      }
    }
  }
`;

const UserSignInMutation = (data, callbacks) => {
  const { onSuccess, onFailure } = callbacks;
  const variables = {
    input: { ...data },
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      onSuccess(response, errors);
    },
    onError: (errors) => {
      onFailure(errors);
    },
  });
};

export default UserSignInMutation;

export const userSignIn = makeMutationAsync(mutation);
