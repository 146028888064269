import { graphql } from "babel-plugin-relay/macro";
import { makeMutationAsync } from "helpers/mutationAsync";

const UserChangePasswordMutation = graphql`
  mutation UserChangePasswordMutation($input: UserChangePasswordInput!) {
    userChangePassword(input: $input) {
      accessToken
      user {
        id
        email
        hasPassword
        role
      }
    }
  }
`;

export default UserChangePasswordMutation;
export const userChangePassword = makeMutationAsync(UserChangePasswordMutation);
