import { graphql } from "babel-plugin-relay/macro";

import makeMutationExec from "helpers/makeMutationExec";
import { makeMutationAsync } from "helpers/mutationAsync";

const UserSignOutMutation = graphql`
  mutation UserSignOutMutation {
    userSignOut(input: { noInput: "" }) {
      success
    }
  }
`;

export const execUserSignOut = makeMutationExec(UserSignOutMutation);
export const userSignOutAsync = makeMutationAsync(UserSignOutMutation);
export default UserSignOutMutation;
