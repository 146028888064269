/**
 * @generated SignedSource<<4a36777c0e6e7d85d738764d897e76d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {
          "noInput": ""
        }
      }
    ],
    "concreteType": "UserSignOutPayload",
    "kind": "LinkedField",
    "name": "userSignOut",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": "userSignOut(input:{\"noInput\":\"\"})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSignOutMutation",
    "selections": (v0/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserSignOutMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8e5adf916661c30cb548c880e2758808",
    "id": null,
    "metadata": {},
    "name": "UserSignOutMutation",
    "operationKind": "mutation",
    "text": "mutation UserSignOutMutation {\n  userSignOut(input: {noInput: \"\"}) {\n    success\n  }\n}\n"
  }
};
})();

node.hash = "869606889c40cc8ce12c0ade0a365914";

module.exports = node;
