import { graphql } from "babel-plugin-relay/macro";

import { makeQueryAsync } from "helpers/queryAsync";

const SignInActionQuery = graphql`
  query SignInActionQuery($email: String!) {
    signInAction(email: $email)
  }
`;

export default SignInActionQuery;
export const getSignInAction = makeQueryAsync(SignInActionQuery);
