import React from "react";
import styled from "styled-components";
import { invoke, isNull, omit } from "lodash";
import { t } from "@lingui/macro";

import { getColor } from "../defaults";
import theme from "theme";
import { Icon } from "ui/icons/Icon";
import { media } from "ui/media";

import { Badge } from "ui/badges/Badge";

const InputBase = React.forwardRef(
  (
    {
      className,
      iconName,
      iconColor,
      SuffixComponent,
      initValue,
      canDisable,
      highlightInitValue,
      ...props
    },
    ref,
  ) => {
    const defaultColor = iconColor || theme.validGrey;
    // Given an event.target, returns a conveniently formatted event.target.value based on event.target.type
    // For example, targets with type "number" will return a numeric value (instead of a string)
    const formatValue = ({ type, value }) => {
      // If an input is blank, the value should be empty string regardless of its type
      // React / the DOM / everybody will hate you if you try to undermine this axiom
      if (value === "") return value;

      switch (type) {
        case "number":
          return Number(value);
        case "tel":
          return value.replace(/\D/g, "");
        default:
          return value;
      }
    };

    // Wrapper around onChange event handlers; allows us to conveniently format events / targets / values
    // "omit" is needed to avoid a quirk of splatting objects with magical defined properties (like events)
    const handleChange = (event) => {
      const value = formatValue(event.target);
      const target = { ...omit(event.target, "value"), value };

      invoke(props, "onChange", { ...omit(event, "target"), target });
    };
    const icon = iconName ? (
      <span className="icon">
        <Icon size="0.8rem" name={iconName} color={defaultColor} />
      </span>
    ) : (
      ""
    );
    const trimmedInitValue =
      typeof initValue === "string" ? initValue.replace("%", "") : initValue;

    return (
      <span className={className}>
        {((canDisable && props.value !== "" && !isNull(props.value)) ||
          trimmedInitValue) &&
          trimmedInitValue !== props.value && (
            <BadgeContainer>
              <PreviousValueBadge grey highlight={highlightInitValue}>
                {t`was`} {canDisable && !trimmedInitValue ? t`N/A` : initValue}
              </PreviousValueBadge>
            </BadgeContainer>
          )}
        <input
          className="ui-input"
          {...omit(props, ["i18nHash", "canDisable", "decimalDigits"])}
          onChange={handleChange}
          ref={ref}
        />
        {SuffixComponent}
        {iconName ? icon : ""}
      </span>
    );
  },
);

export const Input = styled(InputBase)`
  white-space: nowrap;

  > .icon {
    margin-left: -0.8rem;
  }

  > .ui-input {
    padding-right: 1.1rem;
    border: none;
    outline: none;
    background: ${({ theme }) => theme.transparent};
    border-bottom: 1px solid ${({ theme }) => theme.lighterGrey};
    color: ${({ theme }) => theme.black};
    padding-bottom: 0.4rem;
    text-align: left;
    width: 100%;
    font-size: 1rem;
    padding-left: 0;

    ${media.phone} {
      font-size: 16px;
    }

    &:focus {
      border-bottom-color: ${(props) => getColor(props)};
    }
  }
`;

const BadgeContainer = styled.div`
  position: relative;
  height: 0;
`;

const PreviousValueBadge = styled(Badge)`
  position: absolute;
  top: 32px;
  left: 4px;
  padding: 0.1rem 0.3rem;
  font-size: 0.7rem;
`;
