import styled from "styled-components";
import { media } from "ui/media";

export const Container = styled.div`
  margin: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-grow: 1;

  p {
    font-size: inherit;
  }

  a {
    text-decoration: underline;
  }
`;

export const ImgContainer = styled.div`
  margin: 1.5rem auto;
  width: 30%;

  ${media.phone} {
    width: 90%;
  }
  img {
    width: 100%;
  }
`;
