/**
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a neccessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { Suspense, useEffect, lazy } from "react";
import styled from "styled-components";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useLingui } from "@lingui/react";
import URLSearchParams from "url-search-params";

import Login from "containers/LoginPage";
import { WithLocaleSelector } from "components/LocaleSelector";
import Loader from "components/Loader";
import { GlobalStyle } from "components/GlobalStyle";
import { useParams } from "react-router";
import FeatureTogglesProvider from "containers/FeatureTogglesProvider";

const OnboardingDirector = lazy(() => import("directors/OnboardingDirector"));
const CallDirectorV4 = lazy(() => import("directors/CallDirectorV4"));
const Authenticated = lazy(() => import("containers/Authenticated"));
const Retired = lazy(() => import("containers/RetiredPeople"));
const ResetPasswordDirector = lazy(
  () => import("directors/ResetPasswordDirector"),
);
const ErrorPage = lazy(() => import("containers/ErrorPage"));
const SSOLogin = lazy(() => import("containers/SSOLogin"));
const AccountClosed = lazy(() => import("components/AccountClosed"));
const UnsupportedProvince = lazy(() => import("components/UnsupportedProvice"));

const WrappedRetired = WithLocaleSelector(Retired);
const WrappedErrorPage = WithLocaleSelector(ErrorPage);
const WrappedLogin = WithLocaleSelector(Login);
const WrappedResetPasswordDirector = WithLocaleSelector(ResetPasswordDirector);

const App = (props) => {
  const { localeName } = useParams();
  const { i18n } = useLingui();
  const { className } = props;
  const { search } = useLocation();
  const redirectPath = `/${localeName}/dashboard${search}`;

  useEffect(() => {
    const realLanguage = localeName.replace(/-[a-zA-Z]{2}/, (country) =>
      country.toUpperCase(),
    );
    if (
      (localeName && realLanguage !== localeName) ||
      realLanguage !== i18n.locale
    ) {
      props.updateLanguage({ language: realLanguage });
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const listener = (e) => {
      if ("UP navigate" === e.data.name) {
        const queryParams = new URLSearchParams();
        queryParams.append("return_to", e.data.to);

        const redirectPath = `/${localeName}/zendesk?${queryParams.toString()}`;
        window.open(redirectPath, "_blank");
      }
    };

    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
  }, [localeName]);

  return (
    <>
      <GlobalStyle />
      <div className={className}>
        <Suspense fallback={<Loader quiet />}>
          <FeatureTogglesProvider>
            <CallDirectorV4>
              <Routes>
                <Route path="/" element={<Navigate to={redirectPath} />} />
                <Route
                  path={`discovery/:ref`}
                  element={<OnboardingDirector />}
                />
                <Route path={`discovery`} element={<OnboardingDirector />} />

                <Route path={`/retired`} element={<WrappedRetired />} />
                <Route path={`/oops`} element={<WrappedErrorPage />} />
                <Route
                  path={`/login`}
                  element={<WrappedLogin action="email" />}
                />
                <Route
                  path={`/create-password/:createToken`}
                  element={<WrappedLogin action="createPassword" />}
                />
                <Route
                  path={`/reset-password`}
                  element={<WrappedResetPasswordDirector />}
                />
                <Route
                  path={`/change-password/:resetToken`}
                  element={<WrappedResetPasswordDirector />}
                />
                <Route path={`/sso/:token`} element={<SSOLogin />} />
                <Route path={`/account-closed`} element={<AccountClosed />} />
                <Route
                  path={`/unsupported-province`}
                  element={<UnsupportedProvince />}
                />
                <Route path="*" element={<Authenticated />} />
              </Routes>
            </CallDirectorV4>
          </FeatureTogglesProvider>
        </Suspense>
      </div>
    </>
  );
};

export default styled(App)`
  display: flex;
  min-height: 100vh;
  min-width: 100%;
  max-height: 100vh;
  max-width: 100%;
  overflow: auto;

  a {
    color: ${({ theme }) => theme.primary};

    &:hover {
      color: ${({ theme }) => theme.secondary};
    }
  }
`;
