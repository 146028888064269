/**
 * @generated SignedSource<<01a85da64dcef7c4261f9e48a10cc9d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FeatureToggle",
    "kind": "LinkedField",
    "name": "featureToggles",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FeatureTogglesProviderQuery",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FeatureTogglesProviderQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "452969dfdfdf6e8928582727b97f5fd2",
    "id": null,
    "metadata": {},
    "name": "FeatureTogglesProviderQuery",
    "operationKind": "query",
    "text": "query FeatureTogglesProviderQuery {\n  featureToggles {\n    id\n    name\n    enabled\n  }\n}\n"
  }
};
})();

node.hash = "1088c538156da6e099223b7ad44a719b";

module.exports = node;
