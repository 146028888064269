import { commitMutation } from "react-relay";

import environment from "helpers/RelayEnvironment";

/*

Returns a function called from a component that calls mutation with data
and returns a promise

To create mutation execution function

import { makeMutationAsync } from '...';
const LoginAndStuffMutation = graphql`blah...`
export default LoginAndStuffMutation;
export const asyncLoginAndStuff = makeMutationAsync(LoginAndStuffMutation);

To call mutation

//Example usage with promise syntax
import { asyncLoginAndStuff } from '...';
const whateverFunction = () =>
  asyncLoginAndStuff({ email, password })
  .then(data => ...) //success case
  .catch(error => ...); //error case

//Example usage with async / await generator syntax
import { asyncLoginAndStuff } from '...';
const whateverFunction = async () => {
  try {
    const data = await asyncLoginAndStuff({ email, password });
  } catch (error) {
    //handle error (type will be a MutationError subclass; MutationHttpError for non 200's,
    //MutationGeneralError for an error actually returned by the mutation)
  }
};


Note: It is imperative that what is being exported as default is the
mutation itself, i.e., the tagged graphql string, NOT the async function
produced by makeMutationAsync() as Relay's compiler expects the mutation to
be the default export.

*/

export class MutationError extends Error {}

export class MutationGeneralError extends MutationError {
  constructor(mutationErrors) {
    super("Mutation General Error:", mutationErrors.join(", "));
    this.mutationErrors = mutationErrors;
  }
}

export class MutationHttpError extends MutationError {
  constructor(mutationError) {
    super("Mutation HTTP Error:", mutationError);
    this.mutationError = mutationError;
  }
}

export const makeMutationAsync =
  (mutation) =>
  (data = {}, options = {}) => {
    return new Promise((resolve, reject) => {
      const onCompleted = (data, errors) => {
        if (errors && Array.isArray(errors) && errors.length > 0) {
          reject(new MutationGeneralError(errors));
        } else if (data.errors) {
          reject(new MutationGeneralError(data.errors));
        } else {
          resolve(data);
        }
      };

      const onError = (error) => {
        reject(new MutationHttpError(error));
      };
      const variables = {
        input: { ...data },
      };

      return commitMutation(environment, {
        ...options,
        mutation,
        variables,
        onCompleted,
        onError,
      });
    });
  };
