import { graphql } from "babel-plugin-relay/macro";
import makeMutationExec from "helpers/makeMutationExec";
import { makeMutationAsync } from "helpers/mutationAsync";

/*
 * You would be forgiven for thinking that this mutation should be a query.
 * However, it technically has side-effects. Its main use is cache-busting, primarily
 * when the client logs out of the site. Hence, a mutation!
 */
const RefreshFeatureTogglesMutation = graphql`
  mutation RefreshFeatureTogglesMutation {
    refreshFeatureToggles(input: { noInputValue: "" }) {
      featureToggles {
        id
        name
        enabled
      }
    }
  }
`;

export const execRefreshFeatureToggles = makeMutationExec(
  RefreshFeatureTogglesMutation,
);
export const refreshFeatureToggles = makeMutationAsync(
  RefreshFeatureTogglesMutation,
);
export default RefreshFeatureTogglesMutation;
