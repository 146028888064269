import React from "react";
import styled, { useTheme } from "styled-components";
import { Trans, t } from "@lingui/macro";
import { usePageViewEvent } from "hooks";
import { Icon } from "ui/icons/Icon";

export const ConfirmationEmail = ({ onClick, status }) => {
  usePageViewEvent("login: no-password-set-page");
  const theme = useTheme();

  const buttonStates = {
    unsent: {
      text: t`Re-send email`,
    },
    sending: {
      text: t`Sending...`,
    },
    error: {
      color: theme.danger,
      icon: "error-inverse",
      text: (
        <>
          <Trans>
            <strong>Error!</strong> Click to retry
          </Trans>
        </>
      ),
    },
    sent: {
      icon: "check",
      text: t`Email re-sent`,
    },
  };

  const buttonState = buttonStates[status];

  return (
    <pw-row
      style={{ justifyContent: "center", position: "relative" }}
      gutter="md"
    >
      <pw-col
        xxxl="4"
        xl="5"
        lg="6"
        md="8"
        sm="10"
        gutter="sm"
        style={{ maxWidth: "384px" }}
      >
        <LoginTextTitle>Confirmation email</LoginTextTitle>
        <ConfirmationEmailLayout>
          <pw-body size="medium" color={theme.black}>
            <strong>
              <Trans>Awesome!&nbsp;</Trans>
            </strong>
          </pw-body>
          <pw-body size="medium" color={theme.black}>
            <Trans>
              &nbsp;It looks like you have a plan with us, but you haven't
              confirmed your account yet. You should receive a confirmation
              email in your inbox in the next few minutes with some
              instructions.
            </Trans>
          </pw-body>

          <Trans>
            <br />
            <br />

            <pw-body size="medium" color={theme.black}>
              <strong>
                <Trans>Haven't received your confirmation email?</Trans>
              </strong>
            </pw-body>
            <br />
            <pw-body size="medium" color={theme.black}>
              <Trans>No problem. We can re-send it to you.</Trans>
            </pw-body>
            <br />
          </Trans>
          <br />
          <CTAButton onClick={onClick} color={buttonState.color}>
            {buttonState.icon ? (
              <Icon name={buttonState.icon} color="inherit" />
            ) : (
              ""
            )}{" "}
            {buttonState.text}
          </CTAButton>
        </ConfirmationEmailLayout>
      </pw-col>
    </pw-row>
  );
};

const ConfirmationEmailLayout = styled.div`
  margin-bottom: 1rem;

  p {
    color: ${({ theme }) => theme.textGrey};
  }
`;

const CTAButton = styled.button`
  height: 60px;
  padding: 13px 46px 16px 42px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.primary};
  position: relative;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
`;

const LoginTextTitle = styled.div`
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.midGrey};
  position: relative;
  margin-bottom: 20px;
`;
