import React, { useState, useEffect } from "react";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import UpdateLocaleContext from "context/UpdateLocaleContext";
import retry from "helpers/retry";
import moment from "moment";

const I18nLoader = (props) => {
  const [state, setState] = useState(true);
  const loadCatalog = async (language) => {
    const momentCountryImport = ["en-CA", "en-SG", "en-US", "fr-CA"].includes(
      language,
    );
    let momentPromise;

    momentPromise = retry(() => {
      return import(
        /* webpackChunkName: "moment-[request]" */
        `moment/locale/${momentCountryImport ? language.toLowerCase() : language.substring(0, 2).toLowerCase()}`
      );
    });

    const catalog = await retry(
      () =>
        import(
          /* webpackChunkName: "i18n-[request]" */
          `locale/${language}/messages.js`
        ),
    );

    i18n.load(language, catalog.messages);
    i18n.activate(language);

    setState(false);

    if (momentPromise) {
      try {
        await momentPromise;
        moment.locale(language);
      } catch {}
    }
  };

  useEffect(() => {
    loadCatalog(props.language);
  }, [props.language]);

  const { children } = props;
  // Skip rendering when catalog isn't loaded.
  if (state) return null;

  return (
    <I18nProvider i18n={i18n}>
      <UpdateLocaleContext.Provider value={props.updateLanguage}>
        {children}
      </UpdateLocaleContext.Provider>
    </I18nProvider>
  );
};

export default I18nLoader;
