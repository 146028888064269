import React, { Component } from "react";

import ErrorText from "themes/error";
import { sendError } from "helpers/errorTracking";
import Metrics from "helpers/metrics";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
  }

  static getDerivedStateFromError(error) {
    if (error.source) {
      return { errors: error.source.errors };
    } else if (error.error) {
      return { errors: [{ message: error.error.message }] };
    } else {
      return { errors: [{ message: error.toString() }] };
    }
  }

  componentDidCatch(error, info) {
    sendError("ErrorBoundary", { info }, error);
  }

  componentDidMount() {
    Metrics.track("fail-bear");
  }

  render() {
    if (this.state.errors.length > 0) {
      if (this.props.fallback) {
        return React.cloneElement(this.props.fallback, {
          errors: this.state.errors.map((error) => error.message),
        });
      } else {
        return <ErrorText />;
      }
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
