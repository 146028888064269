import React from "react";
import styled from "styled-components";
import { Trans } from "@lingui/macro";

const BackButtonBase = (props) => (
  <a {...props} tabIndex="0">
    <Trans>Back</Trans>
  </a>
);

export const BackButton = styled(BackButtonBase)`
  display: block;
  margin-top: 1em;
`;
